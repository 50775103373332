import type { LinksFunction } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { Button, ConfigProvider, Result } from 'antd';
import reset from 'antd/dist/reset.css?url';
import { useEffect } from 'react';
import { TLtheme } from './theme.js';

export const links: LinksFunction = () => [
  {
    rel: 'stylesheet',
    href: reset,
  },
  {
    rel: 'stylesheet',
    href: '/antd.css',
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const hasGtmScript = document.getElementById('gtm');

    if (!hasGtmScript) {
      const gtm = document.createElement('script');
      gtm.id = 'gtm';
      gtm.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NBKQS49T');`;
      document.head.prepend(gtm);
    }
  }, []);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NBKQS49T"
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
            title="Google Tag Manager"
          />
        </noscript>
        <ConfigProvider theme={TLtheme}>{children}</ConfigProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Result
        status="500"
        subTitle="Oops, something went wrong."
        extra={
          <Button
            type="primary"
            onClick={() =>
              typeof window !== 'undefined' ? window.location.reload() : null
            }
            size="large"
          >
            Reload
          </Button>
        }
      />
    </div>
  );
};

export default function App() {
  return <Outlet />;
}
